<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card>
                <div class="d-flex justify-content-between">
                    <h2>Actions</h2>
                    <b-button variant="primary" @click="$refs.addActionModal.open()" class="mt-1 mr-2">
                        <feather-icon
                            icon="PlusIcon"
                            class="mr-50"
                        />
                        <span class="align-middle">Add</span>
                    </b-button>
                </div>

                <basic-table :columns="columns" :data="actions" v-slot="props">
                    <div v-if="props.column.displayType === 2" class="d-flex justify-content-center">

                        <b-button variant="warning" @click="$refs.editActionModal.open(props.row)" class="mr-1">
                            <feather-icon
                                icon="EditIcon"
                            />
                        </b-button>

                        <b-button variant="danger" @click="removeAction(props.row.id)">
                            <feather-icon
                                icon="Trash2Icon"
                            />
                        </b-button>
                    </div>
                    <span v-else-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <b-badge v-if="props.formattedRow[props.column.field] === 'Yes'" variant="light-success">
                            Yes
                        </b-badge>
                        <b-badge v-else variant="light-danger">
                            No
                        </b-badge>
                    </span>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </basic-table>
            </b-card>
        </b-overlay>

        <ActionAddModal ref="addActionModal" :item-id="itemId" :base-item-id="baseItemId" v-on:actionAdded="loadData"/>

        <ActionEditModal ref="editActionModal" :item-id="itemId" :base-item-id="baseItemId" v-on:actionSaved="loadData"/>
    </div>
</template>
<script>

    import BasicTable from '@/views/components/BasicTable'
    import {BBadge, BButton, BCard, BOverlay} from 'bootstrap-vue'
    import ActionAddModal from '@/views/GenericItem/Item/Actions/ActionAddModal'
    import ActionEditModal from '@/views/GenericItem/Item/Actions/ActionEditModal'
    export default {
        components: {
            ActionEditModal,
            ActionAddModal,
            BasicTable,
            BOverlay,
            BCard,
            BButton,
            BBadge
        },
        props: {
            baseItemId: {
                type: String,
                required: true
            },
            itemId: {
                type: String,
                required: true
            }
        },
        data() {
            return {

                dataLoaded: [],
                actions: [],

                columns: [
                    {
                        label: 'Type',
                        displayType: 0,
                        field: this.typeRenderer,
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search Type'
                        }
                    },
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search Name'
                        }
                    },
                    {
                        label: 'Fully translated',
                        displayType: 1,
                        field(rowObj) {
                            return (rowObj.fully_translated) ? 'Yes' : 'No'
                        },
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Yes / No'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 2,
                        field: 'actions',
                        filterOptions: {
                            enabled: false
                        }
                    }
                ]
            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false

                const thisIns = this
                const loadPromise = this.$http.get(`/api/management/v1/base_item/${  this.baseItemId}/items/${  this.itemId}/actions`)
                loadPromise.then(function(response) {
                    thisIns.actions = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                Promise.all([loadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            removeAction(id) {
                const thisIns = this
                const loadPromise = this.$http.delete(`/api/management/v1/base_item/${  this.baseItemId}/items/${  this.itemId}/actions/${  id}`)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Action removed')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            typeRenderer(rowObj) {
                switch (rowObj.type) {
                case 0:
                    return 'QR Code'
                case 1:
                    return 'ERP Request'
                case 2:
                    return 'Reservation'
                case 3:
                    return 'Android package'
                case 4:
                    return 'Clocks PMS+ Entry'
                default:
                    return 'Unknown'
                }
            }
        },
        mounted() {
            this.loadData()
        }
    }

</script>
