<template>
    <div>
        <h4>Additional images</h4>
        <b-row>
            <b-col md="6" lg="4" :key="image" v-for="image in additionalImages" class="p-2 position-relative">
                <img :src="'/api/management/v1/image/' + image" style="max-width: 100%; max-height: 100%" class="rounded-lg" alt="Image"/>

                <b-button style="position: absolute; top: 10px; right: 10px" variant="danger" @click="removeImage(image)">
                    <feather-icon
                        icon="Trash2Icon"
                    />
                </b-button>
            </b-col>
        </b-row>

        <label for="imageUpload" class="mt-2">Add image:</label>
        <b-form-file
            id="imageUpload"
            v-model="imageFile"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
        />

    </div>
</template>
<script>

    import {BButton, BCol, BFormFile, BRow} from 'bootstrap-vue'

    export default {
        components: {
            BFormFile,
            BButton,
            BRow,
            BCol
        },
        props: {
            baseItemId: {
                type: String,
                required: true
            },
            itemId: {
                type: String,
                required: true
            }
        },
        data() {
            return {

                dataLoaded: false,
                additionalImages: [],

                imageFile: null
            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false

                const thisIns = this
                const loadPromise = this.$http.get(`/api/management/v1/base_item/${  this.baseItemId}/items/${  this.itemId}/additional_images`)
                loadPromise.then(function(response) {
                    thisIns.additionalImages = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            removeImage(imageId) {
                this.dataLoaded = false

                const thisIns = this
                const loadPromise = this.$http.delete(`/api/management/v1/base_item/${  this.baseItemId}/items/${  this.itemId}/additional_images/${  imageId}`)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Image removed')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            }
        },
        watch: {
            imageFile() {
                if (this.imageFile === null) return
                const thisIns = this
                this.dataLoaded = false

                const formData = new FormData()
                formData.append('file', this.imageFile)
                this.$http.post('/api/management/v1/image/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(function(response) {
                    const loadPromise = thisIns.$http.post(`/api/management/v1/base_item/${  thisIns.baseItemId}/items/${  thisIns.itemId}/additional_images/${  response.data}`)
                    loadPromise.then(function() {
                        thisIns.$printSuccess('Image uploaded')
                    }).catch(function(error) {
                        thisIns.$printError(error.response.data)
                    }).finally(function() {
                        thisIns.loadData()
                    })

                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                    thisIns.loadData()
                }).finally(function() {
                    thisIns.imageFile = null
                })
            }
        },
        mounted() {
            this.loadData()
        }
    }

</script>