<template>
    <div>
        <b-card>
            <b-row>
                <b-col md="12" lg="4">
                    <b-overlay :show="!dataLoaded">
                        <div v-if="!editEnabled">
                            <img v-if="item.image && item.image.length > 0" :src="'/api/management/v1/image/' + item.image" style="max-width: 100%; max-height: 200px" alt="Image" class="mb-1 rounded-lg"/>
                            <table>
                                <tr>
                                    <th class="pb-50">
                                        <span class="font-weight-bold mr-2">Name</span>
                                    </th>
                                    <td class="pb-50">
                                        {{item.name}}
                                    </td>
                                </tr>
                                <tr>
                                    <th class="pb-50">
                                        <span class="font-weight-bold mr-2">Categories</span>
                                    </th>
                                    <td class="pb-50">
                                        {{itemCategories}}
                                    </td>
                                </tr>
                                <tr>
                                    <th class="pb-50">
                                        <span class="font-weight-bold mr-2">Description</span>
                                    </th>
                                </tr>
                            </table>

                            <p>
                                {{item.description}}
                            </p>
                        </div>
                        <div v-else>
                            <b-form-group>
                                <b-form-checkbox v-model="editObject.enabled" class="mt-2">Enabled</b-form-checkbox>
                            </b-form-group>
                            <b-form-group>
                                <label>Name: </label>
                                <b-form-input
                                    type="text"
                                    placeholder="Name"
                                    v-model="editObject.name"
                                />
                            </b-form-group>

                            <b-form-group>
                                <label>Categories:</label>
                                <v-select
                                    v-model="editObject.selected_categories"
                                    :reduce="category => category.id"
                                    multiple
                                    label="name"
                                    :options="categories"
                                />

                            </b-form-group>

                            <b-form-group>
                                <label>Description:</label>
                                <b-form-textarea
                                    id="description"
                                    type="text"
                                    placeholder="Description"
                                    v-model="editObject.description"
                                />
                            </b-form-group>

                            <b-form-group>
                                <label>Image:</label>
                                <ImageSelector v-model="editObject.image"/>
                            </b-form-group>
                        </div>

                        <hr/>

                        <b-button v-if="!editEnabled" variant="warning" @click="editEnabled = true" class="mr-1">
                            <feather-icon
                                icon="EditIcon"
                                class="mr-50"
                            />
                            <span class="align-middle">Edit</span>
                        </b-button>

                        <b-button v-if="editEnabled" variant="success" @click="saveItem" class="mr-1">
                            <feather-icon
                                icon="SaveIcon"
                                class="mr-50"
                            />
                            <span class="align-middle">Save</span>
                        </b-button>

                        <b-button v-if="editEnabled" variant="danger" @click="editEnabled = false">
                            <feather-icon
                                icon="XIcon"
                                class="mr-50"
                            />
                            <span class="align-middle">Cancel</span>
                        </b-button>

                        <b-button v-if="editEnabled" variant="danger" @click="removeItem" class="float-right">
                            <feather-icon
                                icon="Trash2Icon"
                                class="mr-50"
                            />
                            <span class="align-middle">Remove</span>
                        </b-button>

                    </b-overlay>
                </b-col>
                <b-col md="12" lg="8">
                    <AdditionalImages :item-id="$route.params.id" :base-item-id="baseItemId"/>
                </b-col>
            </b-row>
        </b-card>

        <b-card>
            <Translations :base-url="`/api/management/v1/base_item/${  baseItemId}/items/${  $route.params.id}`" :fields="[{name: 'Name', field: 'name', type: 0}, {name: 'Description', field: 'description', type: 1}]" />
        </b-card>
        
        <Actions :base-item-id="baseItemId" :item-id="$route.params.id"/>
    </div>
</template>
<script>

    import {
        BButton,
        BCard,
        BCol,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BOverlay,
        BRow
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import AdditionalImages from '@/views/GenericItem/Item/AdditionalImages'
    import Translations from '@/views/Translation/Translations'
    import Actions from '@/views/GenericItem/Item/Actions/Actions'
    import ImageSelector from '@/views/components/ImageSelector'

    export default {
        components: {
            ImageSelector,
            Actions,
            Translations,
            AdditionalImages,
            BOverlay,
            BCard,
            BButton,
            BRow,
            BCol,
            BFormInput,
            BFormTextarea,
            BFormGroup,
            BFormCheckbox,
            vSelect
        },
        data() {
            return {

                dataLoaded: false,
                item: {},
                categories: [],

                editEnabled: false,
                editObject: {},
                imageFile: null
            }
        },
        props: {
            baseItemId: {
                type: String,
                required: true
            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false

                const thisIns = this
                const loadPromise = this.$http.get(`/api/management/v1/base_item/${  this.baseItemId}/items/${  this.$route.params.id}`)
                loadPromise.then(function(response) {
                    thisIns.item = response.data
                    thisIns.editObject = JSON.parse(JSON.stringify(response.data))
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                const categoryPromise = this.$http.get(`/api/management/v1/base_item/${  this.baseItemId}/categories`)
                categoryPromise.then(function(response) {
                    thisIns.categories = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                Promise.all([loadPromise, categoryPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            saveItem() {
                this.dataLoaded = false

                const thisIns = this
                const savePromise = this.$http.put(`/api/management/v1/base_item/${  this.baseItemId}/items/${  this.$route.params.id}`, {
                    enabled: this.editObject.enabled,
                    name: this.editObject.name,
                    description: this.editObject.description,
                    selected_categories: this.editObject.selected_categories,
                    image: this.editObject.image
                })

                savePromise.then(function() {
                    thisIns.$printSuccess('Item saved')
                    thisIns.editEnabled = false
                    thisIns.loadData()
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            removeItem() {

                this.dataLoaded = false

                const thisIns = this
                const removePromise = this.$http.delete(`/api/management/v1/base_item/${  this.baseItemId}/items/${  this.$route.params.id}`)
                removePromise.then(function() {
                    thisIns.$printSuccess('Item removed')
                    thisIns.$nextTick(() => {
                        thisIns.$router.push(`/base_item/${  thisIns.baseItemId}/items`)
                    })
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })
            }
        },
        computed: {
            categoriesMap() {
                if (!this.categories) return {}
                const categories = {}
                this.categories.forEach(function(category) {
                    categories[category.id] = category
                })

                return categories
            },
            itemCategories() {
                if (!this.dataLoaded || !this.item.selected_categories) return
                const categoriesMap = []
                const thisIns = this
                this.item.selected_categories.forEach(function(id) {
                    if (thisIns.categoriesMap[id]) {
                        categoriesMap.push(thisIns.categoriesMap[id].name)
                    } else {
                        categoriesMap.push('Unknown')
                    }
                })
                return categoriesMap.join(', ')
            }
        },
        mounted() {
            this.loadData()
        }
    }

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>