<template>
    <b-modal title="Add action" v-model="addModalActive" size="lg" no-close-on-backdrop>
        <template #default>

            <b-form-group>
                <label for="name">Name:</label>
                <b-form-input
                    id="name"
                    type="text"
                    placeholder="Name"
                    v-model="addObject.name"
                />
            </b-form-group>
            <b-form-group>
                <label for="categories">Type:</label>
                <v-select
                    id="categories"
                    v-model="addObject.type"
                    :reduce="type => type.id"
                    label="name"
                    :options="types"
                />
            </b-form-group>

            <hr/>
            <h6>Action config</h6>
            <QRCode v-if="addObject.type === 0" v-model="addObject.config"/>
            <ERPRequest v-else-if="addObject.type === 1" v-model="addObject.config"/>
            <Reservation v-else-if="addObject.type === 2" v-model="addObject.config"/>
            <AndroidPackage v-else-if="addObject.type === 3" v-model="addObject.config"/>
            <ClocksEntry v-else-if="addObject.type === 4" v-model="addObject.config"/>
        </template>
        <template #modal-footer>
            <b-button variant="danger" @click="addModalActive = false">
                <feather-icon
                    icon="XIcon"
                    class="mr-50"
                />
                <span class="align-middle">Close</span>
            </b-button>

            <b-button variant="primary" @click="addAction">
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">Add</span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>

    import {BButton, BFormGroup, BFormInput, BModal} from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import QRCode from '@/views/GenericItem/Item/Actions/Config/QRCode'
    import ERPRequest from '@/views/GenericItem/Item/Actions/Config/ERPRequest'
    import Reservation from '@/views/GenericItem/Item/Actions/Config/Reservation'
    import AndroidPackage from '@/views/GenericItem/Item/Actions/Config/AndroidPackage'
    import ClocksEntry from '@/views/GenericItem/Item/Actions/Config/ClocksEntry'

    export default {
        components: {
            AndroidPackage,
            Reservation,
            ERPRequest,
            QRCode,
            BButton,
            BModal,
            BFormInput,
            BFormGroup,
            ClocksEntry,
            vSelect
        },
        props: {
            baseItemId: {
                type: String,
                required: true
            },
            itemId: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                addObject: {
                    type: 0,
                    name: '',
                    config: {}
                },
                addModalActive: false,

                types: [
                    {
                        id: 0,
                        name: 'QR Code'
                    },
                    {
                        id: 1,
                        name: 'ERP Request'
                    },
                    {
                        id: 2,
                        name: 'Reservation'
                    },
                    {
                        id: 3,
                        name: 'Android package'
                    },
                    {
                        id: 4,
                        name: 'Clocks Entry'
                    }
                ]
            }
        },
        methods: {
            open() {
                this.addModalActive = true
            },
            addAction() {
                const thisIns = this
                if (this.addObject.config.price !== undefined) {
                    this.addObject.config.price = Number((Number(this.addObject.config.price) * 100).toFixed(0))
                }
                const loadPromise = this.$http.post(`/api/management/v1/base_item/${  this.baseItemId}/items/${  this.itemId}/actions`, this.addObject)
                loadPromise.then(function(response) {
                    thisIns.$printSuccess('Action added')
                    thisIns.addModalActive = false
                    thisIns.addObject = {}
                    thisIns.$emit('actionAdded', response.data.id)
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })
            }
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
